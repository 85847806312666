'use client';

import classNames from 'classnames';
import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';
import { ga4Event } from '@/util/ga4Analytics';
import styles from './index.module.scss';

const getToggleElements = (
  refs: Record<string, RefObject<HTMLInputElement>>
): Record<string, HTMLInputElement> => {
  const toggleElements = Object.entries(refs)
    .filter(([, ref]) => ref.current !== null)
    .reduce(
      (acc, [key, ref]) => {
        if (ref.current) {
          acc[key] = ref.current;
        }
        return acc;
      },
      {} as Record<string, HTMLInputElement>
    );

  return toggleElements;
};

export function MegaNavClient() {
  const serviceMenuToggleRef = useRef<HTMLInputElement>(null);
  const motToggleRef = useRef<HTMLInputElement>(null);
  const servicingToggleRef = useRef<HTMLInputElement>(null);
  const repairsToggleRef = useRef<HTMLInputElement>(null);
  const diagnosticsToggleRef = useRef<HTMLInputElement>(null);
  const resetToggleRef = useRef<HTMLInputElement>(null);
  const garageToggleRef = useRef<HTMLInputElement>(null);
  const blogToggleRef = useRef<HTMLInputElement>(null);
  const menuToggleRef = useRef<HTMLInputElement>(null);

  const refs = {
    serviceMenuToggle: serviceMenuToggleRef,
    motToggle: motToggleRef,
    servicingToggle: servicingToggleRef,
    repairsToggle: repairsToggleRef,
    diagnosticsToggle: diagnosticsToggleRef,
    resetToggle: resetToggleRef,
    garageToggle: garageToggleRef,
    blogToggle: blogToggleRef,
    menuToggle: menuToggleRef,
  };

  useEffect(() => {
    const triggerChangeEvent = (input: HTMLInputElement) => {
      const event = new Event('change', { bubbles: true });
      input.dispatchEvent(event);
    };

    const toggleElements = getToggleElements(refs);

    const handleOverlayClick = () => {
      toggleElements.resetToggle.checked = true;
      toggleElements.serviceMenuToggle.checked = false;
      toggleElements.garageToggle.checked = false;
      toggleElements.blogToggle.checked = false;

      Object.values(toggleElements).forEach(triggerChangeEvent);
    };

    const overlayElement = document.querySelector(`.${styles.overlay}`);
    overlayElement?.addEventListener('click', handleOverlayClick);

    return () => {
      overlayElement?.removeEventListener('click', handleOverlayClick);
    };
  }, []);

  useEffect(() => {
    const toggleElements = getToggleElements(refs);

    const triggerChangeEvent = (input: HTMLInputElement) => {
      const event = new Event('change', { bubbles: true });
      input.dispatchEvent(event);
    };

    const toggleScrolling = () => {
      const anyMenuActive = Object.entries(toggleElements)
        .filter(([key]) => key !== 'resetToggle')
        .some(([, input]) => input.checked);

      document.body.style.overflow = anyMenuActive ? 'hidden' : '';
    };

    const handleServiceMenuToggleChange = () => {
      const { serviceMenuToggle, motToggle, garageToggle, blogToggle, resetToggle } = toggleElements;

      if (serviceMenuToggle.checked) {
        motToggle.checked = true;
        garageToggle.checked = false;
        blogToggle.checked = false;
        triggerChangeEvent(motToggle);
        triggerChangeEvent(garageToggle);
        triggerChangeEvent(blogToggle);
      } else {
        resetToggle.checked = true;
        triggerChangeEvent(resetToggle);
      }
      toggleScrolling();
    };

    const handleMenuToggleChange = () => {
      const { menuToggle, resetToggle, garageToggle, blogToggle } = toggleElements;

      if (!menuToggle.checked) {
        resetToggle.checked = true;
        garageToggle.checked = false;
        blogToggle.checked = false;
        triggerChangeEvent(resetToggle);
        triggerChangeEvent(garageToggle);
        triggerChangeEvent(blogToggle);
      }
      toggleScrolling();
    };

    const handleGarageToggleChange = () => {
      const { serviceMenuToggle, resetToggle, garageToggle, blogToggle } = toggleElements;

      if (garageToggle.checked) {
        serviceMenuToggle.checked = false;
        resetToggle.checked = true;
        blogToggle.checked = false;
        triggerChangeEvent(serviceMenuToggle);
        triggerChangeEvent(resetToggle);
        triggerChangeEvent(blogToggle);
      }
      toggleScrolling();
    };

    const handleBlogToggleChange = () => {
      const { serviceMenuToggle, resetToggle, garageToggle, blogToggle } = toggleElements;

      if (blogToggle.checked) {
        serviceMenuToggle.checked = false;
        resetToggle.checked = true;
        garageToggle.checked = false;
        triggerChangeEvent(serviceMenuToggle);
        triggerChangeEvent(resetToggle);
        triggerChangeEvent(garageToggle);
      }
      toggleScrolling();
    };

    toggleElements.serviceMenuToggle.addEventListener('change', handleServiceMenuToggleChange);
    toggleElements.menuToggle.addEventListener('change', handleMenuToggleChange);
    toggleElements.garageToggle.addEventListener('change', handleGarageToggleChange);
    toggleElements.blogToggle.addEventListener('change', handleBlogToggleChange);

    return () => {
      toggleElements.serviceMenuToggle.removeEventListener('change', handleServiceMenuToggleChange);
      toggleElements.menuToggle.removeEventListener('change', handleMenuToggleChange);
      toggleElements.garageToggle.removeEventListener('change', handleGarageToggleChange);
      toggleElements.blogToggle.removeEventListener('change', handleBlogToggleChange);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        (event.target as HTMLElement).click();
      }
    };

    const focusableLabels = document.querySelectorAll<HTMLElement>('#nav label[role="button"]');
    focusableLabels.forEach((label) => label.addEventListener('keydown', handleKeyDown));

    return () => {
      focusableLabels.forEach((label) => label.removeEventListener('keydown', handleKeyDown));
    };
  }, []);

  const getContainerByToggleId = (toggleId: string): HTMLElement | null => {
    const containerClass = {
      menuToggle: styles.mobileMenu,
      serviceMenuToggle: styles.desktopServiceMenuContainer,
      blogToggle: styles.blogMenu,
      garageToggle: styles.garageMenu,
      motToggle: styles.motMenu,
      servicingToggle: styles.servicingMenu,
      repairsToggle: styles.repairsMenu,
      diagnosticsToggle: styles.diagnosticsMenu,
    }[toggleId];

    return containerClass ? document.querySelector<HTMLElement>(`.${containerClass}`) : null;
  };

  const makeElementsFocusable = (container: HTMLElement, isFocusable: boolean) => {
    const focusableElements = container.querySelectorAll<HTMLElement>('a, label[role="button"]');
    const tabindex = isFocusable ? '0' : '-1';
    const ariaHidden = isFocusable ? 'false' : 'true';

    focusableElements.forEach((element) => {
      element.setAttribute('tabindex', tabindex);
      element.setAttribute('aria-hidden', ariaHidden);
    });
  };

  useEffect(() => {
    const toggleElements = getToggleElements(refs);

    const handleToggleChange = () => {
      Object.values(toggleElements).forEach((toggle) => {
        const container = getContainerByToggleId(toggle.id);
        if (container) makeElementsFocusable(container, toggle.checked);
      });
    };

    Object.values(toggleElements).forEach((toggle) => toggle.addEventListener('change', handleToggleChange));

    handleToggleChange();

    return () => {
      Object.values(toggleElements).forEach((toggle) =>
        toggle.removeEventListener('change', handleToggleChange)
      );
    };
  }, []);

  useEffect(() => {
    const nav = document.getElementById('nav') as HTMLDivElement;

    const track = (event: MouseEvent) => {
      const target = event.target as Element;

      const element: HTMLAnchorElement | HTMLLabelElement | null = target.closest('a, label');

      if (!element) return;

      if (element instanceof HTMLLabelElement) {
        ga4Event('MEGANAV_LABEL_CLICK', { target: element.dataset.analytics });
      }

      if (element instanceof HTMLAnchorElement) {
        ga4Event('MEGANAV_ANCHOR_CLICK', { target: element.href });
      }
    };

    nav.addEventListener('click', track);

    return () => {
      nav.removeEventListener('click', track);
    };
  }, []);

  return (
    <>
      <input
        ref={menuToggleRef}
        type="checkbox"
        id="menuToggle"
        className={classNames(styles.hiddenCheckbox, styles.menuToggle)}
        tabIndex={-1}
      />
      <input
        ref={serviceMenuToggleRef}
        type="checkbox"
        id="serviceMenuToggle"
        className={classNames(styles.hiddenCheckbox, styles.serviceMenuToggle)}
        tabIndex={-1}
      />
      <input
        ref={motToggleRef}
        type="radio"
        name="toggleGroup"
        id="motToggle"
        className={classNames(styles.hiddenCheckbox, styles.motToggle)}
        tabIndex={-1}
      />
      <input
        ref={servicingToggleRef}
        type="radio"
        name="toggleGroup"
        id="servicingToggle"
        className={classNames(styles.hiddenCheckbox, styles.servicingToggle)}
        tabIndex={-1}
      />
      <input
        ref={repairsToggleRef}
        type="radio"
        name="toggleGroup"
        id="repairsToggle"
        className={classNames(styles.hiddenCheckbox, styles.repairsToggle)}
        tabIndex={-1}
      />
      <input
        ref={diagnosticsToggleRef}
        type="radio"
        name="toggleGroup"
        id="diagnosticsToggle"
        className={classNames(styles.hiddenCheckbox, styles.diagnosticsToggle)}
        tabIndex={-1}
      />
      <input
        ref={resetToggleRef}
        type="radio"
        name="toggleGroup"
        id="resetToggle"
        className={classNames(styles.hiddenCheckbox, styles.resetToggle)}
        tabIndex={-1}
      />
      <input
        ref={garageToggleRef}
        type="checkbox"
        id="garageToggle"
        className={classNames(styles.hiddenCheckbox, styles.garageToggle)}
      />
      <input
        ref={blogToggleRef}
        type="checkbox"
        id="blogToggle"
        className={classNames(styles.hiddenCheckbox, styles.blogToggle)}
        tabIndex={-1}
      />
    </>
  );
}
